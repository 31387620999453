<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Liste de culegere</h2>
      <b-button-toolbar class="pb-3">
        <b-button class="mx-1 p-2" id="addItemButton" @click="addItem" variant="primary" v-b-tooltip.hover>
          <i class="fas fa-plus-square" />
          <span class="d-none d-md-inline ml-1">Listă nouă</span>
        </b-button>
      </b-button-toolbar>
    </div>

    <div>
      <div class="row">
        <div class="col-5 p-2">
          <b-card :border-variant="itemStatusBadgeVariantMapper('Pending')" :header="itemStatusLabelMapper('Pending')" class="text-center h-100">
            <b-card-text>
              <div v-for="item in pendingWarehouseOperators" :key="item.id">
                <strong>{{ item.warehouseOperatorFullName }} - {{ item.itemsPending.length }} liste</strong>
                <div class="w-100 d-block" v-for="list in item.itemsPending" :key="list.id">
                  <span style="text-overflow: ellipsis;"><strong>{{ list.listCode }}</strong> | {{ list.orderCode }} | {{ list.specificInformation }}</span>
                </div>
                <div class="pb-1"></div>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-5 p-2">
          <b-card :border-variant="itemStatusBadgeVariantMapper('InProgress')" :header="itemStatusLabelMapper('InProgress')" class="text-center h-100">
            <b-card-text>
              <div v-for="item in inProgressWarehouseOperators" :key="item.id">
                <strong>{{ item.warehouseOperatorFullName }} - {{ item.itemsInProgress.length }} liste</strong>
                <div class="w-100 d-block" v-for="list in item.itemsInProgress" :key="list.id">
                  <span style="text-overflow: ellipsis;"><strong>{{ list.listCode }}</strong> | {{ list.orderCode }} | {{ list.specificInformation }}</span>
                </div>
                <div class="pb-1"></div>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-2 p-2">
          <b-card border-variant="secondary" header="Fără comenzi alocate" class="text-center h-100">
            <div v-for="item in idleWarehouseOperators" :key="item.id">
              {{ item.warehouseOperatorFullName }}
            </div>
          </b-card>
        </div>
      </div>
    </div>

    <b-alert variant="warning" v-if="alertsShouldRefresh" :show="warnOldPendingItems" dismissible>Există <strong>{{ oldPendingItemsCount }}</strong> înregistrări <b-badge class="mx-1" :variant="itemStatusBadgeVariantMapper('Pending')">{{itemStatusLabelMapper('Pending')}}</b-badge> de peste <strong>3 ore</strong>!</b-alert>
    <b-alert variant="warning" v-if="alertsShouldRefresh" :show="warnOldInProgressItems" dismissible>Există <strong>{{ oldInProgressItemsCount }}</strong> înregistrări <b-badge class="mx-1" :variant="itemStatusBadgeVariantMapper('InProgress')">{{itemStatusLabelMapper('InProgress')}}</b-badge> de peste <strong>2 ore</strong>!</b-alert>

    <div class="d-flex justify-content-center p-2">
      <div clas="p-1" v-for="item in itemStatusCodes" :key="item">
        <b-badge class="ml-3 mr-1" :variant="itemStatusBadgeVariantMapper(item)">{{itemStatusLabelMapper(item)}}</b-badge> <strong>{{ filteredCount(item) }}</strong> înreg.
      </div>
    </div>

    <div class="d-flex flex-row">
      <div class="p-1">
        <b-btn @click="refreshList">
          Reîncarcă lista
        </b-btn>
      </div>

      <div class="p-1">
        <b-button :pressed="itemsTableShowId" @click="itemsTableShowId = !itemsTableShowId">
            Afișează ID-uri
        </b-button >
      </div>

      <div class="p-1 flex-fill">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              v-model="itemsFilter"
              type="search"
              debounce="80"
              placeholder="Filtrează"/>
            <b-input-group-append>
              <b-button :disabled="!itemsFilter" @click="itemsFilter = ''">Resetează</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>

      <div class="p-1" style="flex-grow: 0; flex-shrink: 0; width: 200px">
        <multiselect
          v-model="itemsCurrentStatusFilter"
          placeholder="Filtre stare"
          selected-label="Selectat"
          select-label="Alege cu Enter"
          deselect-label="Elimină cu Enter"
          :options="itemsCurrentStatusFilterOptions"
          :allow-empty="true"
          :multiple="false">
          <template
            slot="option"
            slot-scope="props"
          >
            <b-badge :variant="props.option.variant" class="text-uppercase">{{props.option.label}}</b-badge>
          </template><template
            slot="singleLabel"
            slot-scope="props"
          >
            <b-badge :variant="props.option.variant" class="text-uppercase">{{props.option.label}}</b-badge>
          </template>
          <template
            slot="tag"
            slot-scope="props">
            <b-badge :variant="props.option.variant" class="mr-1 text-uppercase">{{props.option.label}}</b-badge>
          </template>
          <template
            slot="clear">
          </template>
        </multiselect>
      </div>

      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>

      <div class="p-2" v-if="items && items.length">
        {{ items.length }} înregistrări totale
      </div>
    </div>

    <b-table
      show-empty
      small
      striped
      no-footer-sorting
      ref="itemsTable"
      :empty-text="tableBusy ? 'Procesare...' : 'Lista nu are înregistrări'"
      :foot-clone="true"
      primary-key="id"
      :items="items"
      :fields="itemsTableFields"
      :busy.sync="tableBusy"
      :per-page="itemsPerPage"
      :current-page="itemsCurrentPage"
      :filter="itemsFilter"
      class="tweaked-table">
      <template #cell(__actions)="data">
        <b-button
        v-if="data.item.currentStatus !== 'Draft'"
          class="ml-2"
          size="sm"
          @click="showDetails(data.item.id)"
          v-b-tooltip.hover title="Vezi detalii">
          <i class="fas fa-info-circle" />
        </b-button>
        <b-button
          v-if="data.item.currentStatus === 'Draft'"
          class="ml-2"
          size="sm"
          @click="editDraft(data.item.id)"
          v-b-tooltip.hover title="Editează">
          <i class="fas fa-edit" />
        </b-button>
      </template>

      <template #cell(id)="data">
        <span>{{ data.value }}</span>
        <b-button
          variant="link"
          size="sm"
          v-b-tooltip.hover="'Copiază valoarea'"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="clipboardErrorHandler"
          v-clipboard="() => data.value"><i class="far fa-copy" />
        </b-button>
      </template>

      <template #cell(currentStatus)="data">
        <b-badge
          :variant="itemStatusBadgeVariantMapper(data.value)"
          class="badge-lg">
          {{itemStatusLabelMapper(data.value)}}
        </b-badge>
      </template>
    </b-table>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>
    </div>

    <b-modal
      body-class="position-static"
      ref="editDraftModal"
      id="edit-draft-modal"
      title="Editare ciornă"
      size="xl"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      scrollable>
      <b-overlay :show="draftItemBusy" no-wrap rounded>
        <template #overlay><b-spinner v-if="!draftItemBusyText"></b-spinner><span>{{ draftItemBusyText }}</span></template>
      </b-overlay>
      <b-container fluid v-if="draftItem">
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>ID:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ draftItem.id }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">
            <span>{{ draftItem.listCode }}</span>
            <b-btn
            class="ml-2"
              variant="secondary"
              size="sm"
              @click="submitDeleteDraftItem">
              <i class="fas fa-trash-alt" />
            </b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Comanda:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ draftItem.orderCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Op. alocat:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">
            <multiselect
              v-model="draftItemWarehouseOperatorSelection"
              track-by="id"
              no-result="Niciun rezultat nu corespunde"
              no-options="Caută după cod sau denumire"
              placeholder="Caută după cod sau denumire"
              select-label=""
              selected-label=""
              deselect-label=""
              label="warehouseOperatorFullName"
              :searchable="true"
              :options="draftItemWarehouseOperatorOptions"
              :allow-empty="true"
              :options-limit="20"
              :limit="5"
              :tabindex="1"
            >
            </multiselect>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Informații specifice:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ draftItem.specificInformation }}</b-col>
        </b-row>
        <h3>Linii recepție</h3>
        <table class="table table-small tweaked-table table-sm">
          <thead>
            <th>#</th>
            <th>Cod</th>
            <th>Denumire</th>
            <th>Lot</th>
            <th>Celula</th>
            <th class="text-right">Cantitate</th>
            <th></th>
          </thead>
          <tbody v-for="(line, idx) in draftItem.items" :key="line.picklistItemId">
            <tr v-if="line !== draftItemEditingLine">
              <td>{{ idx + 1 }}</td>
              <td>{{ line.productCode }}</td>
              <td>{{ line.description }}<span v-if="line.specificInformation"><br>{{ line.specificInformation }}</span></td>
              <td><span v-if="line.batchCode">{{ line.batchCode}}</span><span v-if="line.batchExpirationDate"> / {{ line.batchExpirationDate | displayAsDate }}</span></td>
              <td>{{ line.cellCode }}</td>
              <td class="text-right">{{ line.requestedProductsCount }}</td>
              <td>
                <b-button
                  variant="secondary"
                  size="sm"
                  :disabled="xhrRequestRunning"
                  @click="submitDeleteDraftLine(line)"
                  v-if="!draftItemEditingLine"
                >
                  <i class="fas fa-trash-alt" />
                </b-button>
              </td>
            </tr>
            <tr v-if="line === draftItemEditingLine">
              <td colspan="3">
                <multiselect
                  v-model="draftItemProductSelection"
                  track-by="id"
                  no-result="Niciun rezultat nu corespunde"
                  no-options="Caută după cod sau denumire"
                  placeholder="Caută după cod sau denumire"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  label="label"
                  :searchable="true"
                  :options="draftItemProductOptions"
                  :allow-empty="false"
                  :options-limit="20"
                  :limit="5"
                  :tabindex="1"
                  @select="draftItemProductSelected"
                  open-direction="top"
                ></multiselect>
              </td>
              <td>
                <multiselect
                  v-if="draftItemProductSelection && !draftItemProductSelectionNoInventory"
                  v-model="draftItemProductBatchSelection"
                  track-by="batchId"
                  no-result="Niciun rezultat nu corespunde"
                  no-options="Caută după cod"
                  placeholder="Caută după cod"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  :searchable="true"
                  :options="draftItemProductBatchOptions"
                  :allow-empty="false"
                  :options-limit="20"
                  :limit="5"
                  :tabindex="1"
                  @select="draftItemProductBatchSelected"
                  open-direction="top"
                >
                  <template #singleLabel="props">
                    <span v-if="!props.option.batchId">-</span>
                    <span v-else>
                      {{ props.option.batchCode }}<span v-if="props.option.expiresAt"> / {{  props.option.expiresAt | displayAsDate }}</span>
                    </span>
                  </template>
                  <template #option="props">
                    <span v-if="!props.option.batchId">-</span>
                    <span v-else>
                      {{ props.option.batchCode }}<span v-if="props.option.expiresAt"> / {{  props.option.expiresAt | displayAsDate }}</span>
                    </span>
                    <span> | disp: {{ props.option.storedQuantity }}</span>
                  </template>
                </multiselect>
                <span v-if="draftItemProductSelectionNoInventory">
                  Produs fără stoc
                </span>
              </td>
              <td>
                <multiselect
                  v-if="draftItemProductBatchSelection && !draftItemProductSelectionNoInventory"
                  v-model="draftItemStorageCellSelection"
                  track-by="storageCellId"
                  no-result="Niciun rezultat nu corespunde"
                  no-options="Caută după cod"
                  placeholder="Caută după cod"
                  select-label=""
                  selected-label=""
                  deselect-label=""
                  :searchable="true"
                  :options="draftItemStorageCellOptions"
                  :allow-empty="false"
                  :options-limit="20"
                  :limit="5"
                  :tabindex="1"
                  @select="draftItemStorageCellSelected"
                  open-direction="top"
                >
                  <template #singleLabel="props">
                    {{ props.option.storageCellLabel }}
                  </template>
                  <template #option="props">
                    {{ props.option.storageCellLabel }} | disp: {{props.option.storedQuantity}}
                  </template>
                </multiselect>
              </td>
              <td>
                <b-form-input
                  class="text-right"
                  v-model="draftItemQuantity"
                  v-if="!draftItemProductSelectionNoInventory"
                  :readonly="!draftItemStorageCellSelection"
                />
                <span v-if="draftItemStorageCellSelection"><br>Disp.: {{ draftItemStorageCellSelection.storedQuantity }}</span>
              </td>
              <td>
                <b-button
                  variant="primary"
                  size="sm"
                  :disabled="xhrRequestRunning || !draftItemEditingLineValid"
                  @click="submitEditingDraftLine()"
                >
                  <i class="fas fa-check" />
                </b-button>
                <b-button
                  variant="primary"
                  size="sm"
                  :disabled="xhrRequestRunning"
                  @click="abortEditingDraftLine()"
                >
                  <i class="fas fa-times" />
                </b-button>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!draftItemEditingLine">
            <tr>
              <td colspan="7" class="text-center">
                <b-button variant="success" size="sm" @click="addDraftLine">
                  <i class="fas fa-plus" /> Adaugă linie
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="my-2 text-center">

          <span id="optimizeStorageCellSelectionWrapper" class="d-inline-block mx-1" tabindex="0">
            <b-button
              variant="primary"
              size="sm"
              @click="submitOptimizeStorageCellSelection"
              :disabled="!draftItem.items || !draftItem.items.length || draftItemEditingLine">
              <font-awesome-icon :icon="['fas', 'inventory']" class="mr-1"/>Optimizează selecția celulelor
            </b-button>
          </span>
          <b-tooltip v-if="!draftItem.items || !draftItem.items.length" target="optimizeStorageCellSelectionWrapper" triggers="hover">
            Trebuie ca lista să aibă cel puțin o linie
          </b-tooltip>

          <span id="assignDraftItemWrapper" class="d-inline-block mx-1" tabindex="0">
            <b-button
              variant="primary"
              size="sm"
              @click="submitAssignDraftItem"
              :disabled="!draftItemWarehouseOperatorSelection || !draftItem.items || !draftItem.items.length || draftItemEditingLine">
              <font-awesome-icon :icon="['fas', 'scanner']" class="mr-1"/>Trimite comanda spre operator
            </b-button>
          </span>
          <b-tooltip v-if="!draftItemWarehouseOperatorSelection || !draftItem.items || !draftItem.items.length" target="assignDraftItemWrapper" triggers="hover">
            Trebuie ales un operator și adăugate linii în cadrul listei
          </b-tooltip>
        </div>
        <div class="mb-2">&nbsp;</div>
      </b-container>
    </b-modal>

    <b-modal
      ref="itemDetailsModal"
      id="item-details-modal"
      title="Detalii"
      size="xl"
      hide-footer
      scrollable>
      <b-container fluid v-if="detailedItem">
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>ID:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.id }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.listCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Comanda:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.orderCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Op. alocat:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.warehouseOperatorFullName }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Informații specifice:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.specificInformation }}</b-col>
        </b-row>
        <h3>Linii recepție</h3>
        <b-table
          show-empty
          small
          striped
          no-footer-sorting
          ref="itemsTable"
          empty-text="Lista nu are înregistrări"
          :foot-clone="true"
          primary-key="id"
          :fields="detailTableFields"
          :items="detailedItem.items"
          :busy.sync="detailTableBusy"
          class="tweaked-table">
          <template v-slot:cell(lineIndex)="data">
            {{ data.index + 1 }}.
          </template>

          <template #cell(pickedProductsCount)="data">
            <span
              v-b-tooltip.hover="'Cantitate diferită'"
              class="text-secondary mr-1"
              v-if="data.item.currentStatus !== 'Unprocessed' && data.item.requestedProductsCount !== data.item.selections.reduce((acc, q) => acc + q.pickedProductsCount, 0)">
                <i class="fas fa-exclamation-triangle"/>
              </span>
            <span>{{ data.item.selections.reduce((acc, q) => acc + q.pickedProductsCount, 0) }}</span>
          </template>

          <template #cell(currentStatus)="data">
            <b-badge
              :variant="detailItemStatusBadgeVariantMapper(data.value)"
              class="badge-lg">
              {{detailItemStatusLabelMapper(data.value)}}
            </b-badge>
          </template>

          <template #row-details="row">
            <div v-for="selection in row.item.selections" v-bind:key="selection.id" class="row">
              <div class="col-4"></div>
              <div class="col-2">
                <strong>Celula:</strong> {{ selection.cellCode }}
                <span v-b-tooltip.hover="'Celulă diferită'" class="text-secondary" v-if="selection.cellCode !== row.item.cellCode"><i class="fas fa-exclamation-circle"/></span>
                <span v-b-tooltip.hover="'Celula cerută'" class="text-success" v-if="selection.cellCode === row.item.cellCode"><i class="fas fa-fa-check"/></span>
              </div>
              <div class="col-2">
                <strong>Lot:</strong> {{selection.productBatchCode || '-' }}&nbsp;
                <span v-b-tooltip.hover="'Lot diferit'" class="text-warning" v-if="selection.productBatchId !== row.item.batchId"><i class="fas fa-exclamation-triangle"/></span>
                <span v-b-tooltip.hover="'Lot cerut'" class="text-success" v-if="selection.productBatchId === row.item.batchId"><i class="fas fa-check"/></span>
              </div>
              <div class="col-2">
                <div v-if="selection.productBatchExpiresAt">
                  <strong>Exp:</strong> {{ selection.productBatchExpiresAt | displayAsDate }}
                </div>
              </div>
              <div class="col-2">
                <strong>Cant.:</strong> {{ selection.pickedProductsCount }}
              </div>
            </div>
          </template>

          <template #foot(requestedProductsCount)>
            {{ detailedItem.items.reduce((acc, q) => acc + q.requestedProductsCount, 0) }}
          </template>
          <template #foot(pickedProductsCount)>
            {{ detailedItem.items.reduce((acc, q) => acc + q.selections.reduce((iacc, s) => iacc + s.pickedProductsCount, 0), 0) }}
          </template>
        </b-table>
      </b-container>
    </b-modal>

    <b-modal
      ref="addItemModal"
      id="add-item-modal"
      title="Adaugă listă de culegere"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :ok-disabled="addItemBusy"
      :cancel-disabled="addItemBusy"
      @ok="commitAddItem">
      <b-container fluid>
        <div class="row">
          <div class="col">
            <b-form-group label="Cod comandă">
              <b-form-input v-model="addItemOrderCode" :disabled="addItemBusy"/>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Comentarii">
              <b-textarea v-model="addItemSpecificInformation" :disabled="addItemBusy"></b-textarea>
            </b-form-group>
          </div>
        </div>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import BigNumber from 'bignumber.js';
import toasts from '@/services/toasts';
import { mapState } from 'vuex';

import wmsSettings from '@/services/wms';

const { DateTime } = require('luxon');

const { warehouseId, httpClient } = wmsSettings;

export default {
  name: 'Picklists',
  components: {
  },

  data() {
    return {
      alertsShouldRefresh: true,

      itemsPerPage: 50,
      itemsCurrentPage: 1,
      itemsFilter: '',

      itemStatusCodes: [
        'Draft',
        'Pending',
        'InProgress',
        'Processed',
        'ProcessedWithException',
        'Abandoned',
      ],

      itemsCurrentStatusFilter: null,
      itemsCurrentStatusFilterOptions: [
        {
          label: 'Ciornă',
          variant: 'dark',
          value: 'Draft',
        },
        {
          label: 'În așteptare',
          variant: 'secondary',
          value: 'Pending',
        },
        {
          label: 'În lucru',
          variant: 'primary',
          value: 'InProgress',
        },
        {
          label: 'Abandonată',
          variant: 'light',
          value: 'Abandoned',
        },
        {
          label: 'Procesată',
          variant: 'success',
          value: 'Processed',
        },
        {
          label: 'Proc. cu exc.',
          variant: 'warning',
          value: 'ProcessedWithException',
        },
      ],

      warehouseOperatorOptions: [],

      pendingWarehouseOperators: [],
      inProgressWarehouseOperators: [],
      idleWarehouseOperators: [],

      itemsUnfiltered: [],
      itemsTableShowId: false,
      itemsTableFieldsComplete: [
        {
          key: '__actions',
          label: '',
        },
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Data',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'listCode',
          label: 'Cod listă',
        },
        {
          key: 'orderCode',
          label: 'Cod comandă',
        },
        {
          key: 'specificInformation',
          label: 'Alte info.',
        },
        {
          key: 'comment',
          label: 'Comentarii',
        },
        {
          key: 'warehouseOperatorFullName',
          label: 'Operator',
        },
        {
          key: 'startedAt',
          label: 'Început',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'processedAt',
          label: 'Procesat',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'currentStatus',
          label: 'Stare',
        },
      ],
      tableBusy: false,

      // Add list stuff
      addItemBusy: false,
      addItemOrderCode: null,
      addItemSpecificInformation: null,

      // Draft modal stuff
      draftItem: null,
      draftItemEditingLine: null,
      draftItemBusy: false,
      draftItemBusyText: null,

      draftItemWarehouseOperatorOptions: [],
      draftItemWarehouseOperatorSelection: null,

      draftItemProductOptions: [],
      draftItemProductSelection: null,

      draftItemProductSelectionNoInventory: false,

      draftItemProductBatchOptions: [],
      draftItemProductBatchSelection: null,

      draftItemStorageCellOptions: [],
      draftItemStorageCellSelection: null,

      draftItemQuantity: 0,

      // Details modal stuff
      detailedItem: null,
      detailTableBusy: false,
      detailTableFields: [
        {
          key: 'lineIndex',
          label: '#',
        },
        {
          key: 'cellCode',
          label: 'Celula',
        },
        {
          key: 'productCode',
          label: 'Cod',
        },
        {
          key: 'eanCode',
          label: 'EAN',
        },
        {
          key: 'description',
          label: 'Descriere',
        },
        {
          key: 'batchCode',
          label: 'Lot',
        },
        {
          key: 'batchExpirationDate',
          label: 'Exp. lot',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
          },
        },
        {
          key: 'requestedProductsCount',
          label: 'Cantitate cerută',
          thClass: 'col-numeric-value',
          class: 'col-numeric-value text-right',
        },
        {
          key: 'pickedProductsCount',
          label: 'Cantitate culeasă',
          thClass: 'col-numeric-value',
          class: 'col-numeric-value text-right',
        },
        {
          key: 'currentStatus',
          label: 'Stare',
          thClass: 'text-center',
          class: 'text-center',
        },
      ],
    };
  },

  created() {
    this.loadInitialData();
  },

  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
    displayAsPercentage(value, decimals = 0) {
      return `${new BigNumber(value).times(100).decimalPlaces(decimals).toString()} %`;
    },
  },

  computed: {
    draftItemEditingLineValid() {
      return this.draftItemProductSelection
      && this.draftItemProductBatchSelection
      && this.draftItemStorageCellSelection
      && BigNumber(this.draftItemQuantity).gt(0)
      && BigNumber(this.draftItemQuantity).lte(this.draftItemStorageCellSelection.storedQuantity);
    },

    warnOldPendingItems() {
      if (!this.itemsUnfiltered) {
        return false;
      }

      return !!this.itemsUnfiltered.find((item) => (item.currentStatus === 'Pending' && DateTime.fromISO(item.createdAt).plus({ hours: 3 }) < DateTime.now()));
    },

    oldPendingItemsCount() {
      if (!this.itemsUnfiltered) {
        return false;
      }

      return this.itemsUnfiltered.reduce((acc, item) => acc + ((item.currentStatus === 'Pending' && DateTime.fromISO(item.createdAt).plus({ hours: 3 }) < DateTime.now()) ? 1 : 0), 0);
    },

    warnOldInProgressItems() {
      if (!this.itemsUnfiltered) {
        return false;
      }

      return !!this.itemsUnfiltered.find((item) => (item.currentStatus === 'InProgress' && DateTime.fromISO(item.startedAt).plus({ hours: 2 }) < DateTime.now()));
    },

    oldInProgressItemsCount() {
      if (!this.itemsUnfiltered) {
        return false;
      }

      return this.itemsUnfiltered.reduce((acc, item) => acc + ((item.currentStatus === 'InProgress' && DateTime.fromISO(item.startedAt).plus({ hours: 2 }) < DateTime.now()) ? 1 : 0), 0);
    },

    items() {
      if (!this.itemsUnfiltered) {
        return [];
      }

      if (!this.itemsCurrentStatusFilter) {
        return this.itemsUnfiltered;
      }

      return this.itemsUnfiltered.filter((item) => item.currentStatus === this.itemsCurrentStatusFilter.value);
    },

    itemsTableFields() {
      if (!this.itemsTableShowId) { return this.itemsTableFieldsComplete.filter((x) => x.key !== 'id'); }
      return this.itemsTableFieldsComplete;
    },

    ...mapState(['xhrRequestRunning']),
  },

  methods: {
    async refreshList() {
      this.alertsShouldRefresh = false;

      await this.loadInitialData();
      toasts.success('Lista a fost reîncărcată');

      this.alertsShouldRefresh = true;
    },

    async loadInitialData() {
      this.tableBusy = true;
      try {
        const picklists = await httpClient.get(`/user-app/picklist/list?warehouseId=${warehouseId}`);
        this.itemsUnfiltered = picklists.data;
        this.itemsCurrentPage = 1;

        const warehouseOperators = await httpClient.get(`/user-app/warehouse-operator/list?warehouseId=${warehouseId}`);
        this.warehouseOperatorOptions = warehouseOperators.data;

        const pendingInProgressByWarehouseOperator = this.itemsUnfiltered.reduce((grouping, item) => {
          if (item.currentStatus !== 'Pending' && item.currentStatus !== 'InProgress') {
            return grouping;
          }

          // eslint-disable-next-line no-param-reassign
          grouping[item.warehouseOperatorId] = grouping[item.warehouseOperatorId] || {
            ...item,
            itemsPending: [],
            itemsInProgress: [],
          };

          if (item.currentStatus === 'Pending') {
            grouping[item.warehouseOperatorId].itemsPending.push(item);
          }
          if (item.currentStatus === 'InProgress') {
            grouping[item.warehouseOperatorId].itemsInProgress.push(item);
          }
          return grouping;
        }, {});

        const assignedWarehouseOperatorIds = Object.keys(pendingInProgressByWarehouseOperator);

        this.pendingWarehouseOperators = assignedWarehouseOperatorIds.filter((x) => pendingInProgressByWarehouseOperator[x].itemsPending.length > 0).map((x) => pendingInProgressByWarehouseOperator[x]);
        this.inProgressWarehouseOperators = assignedWarehouseOperatorIds.filter((x) => pendingInProgressByWarehouseOperator[x].itemsInProgress.length > 0).map((x) => pendingInProgressByWarehouseOperator[x]);
        this.idleWarehouseOperators = this.warehouseOperatorOptions.filter((x) => assignedWarehouseOperatorIds.indexOf(x.id) === -1);
      } finally {
        this.tableBusy = false;
      }
    },

    addItem() {
      this.addItemBusy = false;
      this.addItemOrderCode = null;
      this.addItemSpecificInformation = null;

      this.$refs.addItemModal.show();
    },

    async commitAddItem(evt) {
      evt.preventDefault();

      if (!this.addItemOrderCode) {
        toasts.error('Trebuie completat codul de comandă');
        return;
      }

      try {
        this.addItemBusy = true;

        const addItemObject = {
          warehouseId,
          orderCode: this.addItemOrderCode,
          specificInformation: this.addItemSpecificInformation,
          addPicklistItemModels: [],
        };

        const addResult = await httpClient.post('/user-app/picklist', addItemObject);
        const picklistId = addResult.data;

        toasts.success(`Lista a fost adăugată (${picklistId})`);
        this.$refs.addItemModal.hide();

        // Don't wait, reload right away
        this.loadInitialData();
      } catch (err) {
        toasts.error(`Lista nu a putut fi adăugată: ${err}`);
      } finally {
        this.addItemBusy = false;
      }
    },

    async editDraft(id) {
      const detailedItem = await httpClient.get(`/user-app/picklist/details/${id}`);
      this.draftItem = detailedItem.data;
      this.draftItemEditingLine = null;

      this.draftItem.items = this.draftItem.items.map((item) => {
        let showDetails = item.currentStatus === 'ProcessedWithException' && !!item.selections && item.selections.length;
        if (!showDetails) {
          showDetails = !!item.selections && item.selections.find((sel) => sel.cellCode !== item.cellCode);
        }
        return Object.assign(item, { _showDetails: showDetails });
      });

      this.draftItemWarehouseOperatorOptions = [...this.warehouseOperatorOptions];
      this.draftItemWarehouseOperatorSelection = this.warehouseOperatorOptions.find((x) => x.id === this.draftItem.warehouseOperatorId);

      console.log(this.draftItem);

      this.$refs.editDraftModal.show();
    },

    async draftItemProductSelected(selectedOption) {
      this.draftItemEditingLine.productId = null;
      this.draftItemEditingLine.productCode = null;
      this.draftItemEditingLine.eanCode = null;
      this.draftItemEditingLine.description = null;

      this.draftItemProductSelectionNoInventory = false;

      this.draftItemProductBatchOptions = [];
      this.draftItemProductBatchSelection = null;

      this.draftItemStorageCellOptions = [];
      this.draftItemStorageCellSelection = null;

      if (!selectedOption) {
        return;
      }

      this.draftItemEditingLine.productId = selectedOption.id;
      this.draftItemEditingLine.productCode = selectedOption.productCode;
      this.draftItemEditingLine.eanCode = selectedOption.eanCode;
      this.draftItemEditingLine.description = selectedOption.description;

      this.draftItemEditingLine.productBatchId = null;
      this.draftItemEditingLine.storageCellId = null;

      this.draftItemBusyText = `Se preiau stocurile disponibile pentru produsul ${selectedOption.description} (${selectedOption.productCode})`;
      this.draftItemBusy = true;
      try {
        const batches = await httpClient.get(`/user-app/product/inventory?warehouseId=${warehouseId}&productId=${selectedOption.id}`);

        this.draftItemProductBatchOptions = Object.values(batches.data.inventoryEntries.reduce((acc, entry) => {
          if (acc[entry.batchId || '']) {
            const match = acc[entry.batchId || ''];
            match.storedQuantity += entry.storedQuantity;
            match.storageCells.push(entry);
          } else {
            acc[entry.batchId || ''] = {
              batchId: entry.batchId || '',
              batchCode: entry.batchCode,
              expiresAt: entry.expiresAt,
              storedQuantity: entry.storedQuantity,
              storageCells: [entry],
            };
          }
          return acc;
        }, {}));

        const nullBatchEntry = this.draftItemProductBatchOptions.find((entry) => entry.productBatchId === '');
        if (nullBatchEntry) {
          nullBatchEntry.productBatchId = null;
        }

        this.draftItemProductBatchSelection = null;

        this.draftItemStorageCellOptions = [];
        this.draftItemStorageCellSelection = null;

        if (!this.draftItemProductBatchOptions.length) {
          this.draftItemProductSelectionNoInventory = true;
        }
      } catch (err) {
        toasts.error(`Eroare la preluarea stocurilor: ${err}`);
      } finally {
        this.draftItemBusyText = null;
        this.draftItemBusy = false;
      }
    },

    draftItemProductBatchSelected(selectedOption) {
      this.draftItemStorageCellOptions = [];
      this.draftItemStorageCellSelection = null;

      if (selectedOption) {
        this.draftItemStorageCellOptions = selectedOption.storageCells;
      }
    },

    draftItemStorageCellSelected(selectedOption) {
      console.log(selectedOption);
    },

    async addDraftLine() {
      this.draftItemBusy = true;
      try {
        this.draftItemBusyText = 'Se preiau produsele...';
        const items = await httpClient.get('/user-app/product/list');
        this.draftItemProductOptions = items.data.map((item) => ({ ...item, label: `${item.productCode} - ${item.description} (${item.eanCode})`, value: item.id }));

        this.draftItemEditingLine = {
          isPersisted: false,
          picklistItemId: `draft-${Date.now()}`,
          productCode: '',
        };

        this.draftItem.items.push(this.draftItemEditingLine);
      } finally {
        this.draftItemBusyText = null;
        this.draftItemBusy = false;
      }
    },

    async submitDeleteDraftItem() {
      if (!this.draftItem) {
        return;
      }

      if (!(await this.$bvModal.msgBoxConfirm('Confirmi ștergerea acestei liste?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      try {
        this.draftItemBusy = true;
        this.draftItemBusyText = 'Se șterge linia aleasă...';

        await httpClient.delete(`/user-app/picklist/${this.draftItem.id}`);
        toasts.success('Lista a fost ștearsă');

        this.$refs.editDraftModal.hide();
        this.draftItem = null;

        await this.loadInitialData();
      } catch (ex) {
        toasts.error(`Problemă la ștergere: ${ex}`);
      } finally {
        this.draftItemBusyText = null;
        this.draftItemBusy = false;
      }
    },

    async submitDeleteDraftLine(line) {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi ștergerea acestei linii din listă?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      try {
        this.draftItemBusy = true;
        this.draftItemBusyText = 'Se șterge linia aleasă...';

        await httpClient.delete(`/user-app/picklist-item/${this.draftItem.id}/${line.picklistItemId}`);
        toasts.success('Linia a fost ștearsă');

        this.draftItem.items = this.draftItem.items.filter((item) => item !== line);
      } catch (ex) {
        toasts.error(`Problemă la ștergere: ${ex}`);
      } finally {
        this.draftItemBusyText = null;
        this.draftItemBusy = false;
      }
    },

    async submitEditingDraftLine() {
      // TODO: validate
      // this.draftItemEditingLine = null;
      try {
        this.draftItemBusy = true;
        this.draftItemBusyText = 'Se salvează datele...';

        const payload = {
          picklistId: this.draftItem.id,
          warehouseId,
          productId: this.draftItemProductSelection.id,
          productBatchId: (this.draftItemProductBatchSelection && this.draftItemProductBatchSelection.batchId) || null,
          storageCellId: this.draftItemStorageCellSelection.storageCellId,
          requestedProductsCount: BigNumber(this.draftItemQuantity).toNumber(3),
          specificInformation: null,
        };

        const picklistItemId = await httpClient.post('/user-app/picklist-item', payload);
        toasts.success(`Linia a fost salvată (ID ${picklistItemId})`);
        this.draftItemEditingLine = null;

        const updatedPicklist = await httpClient.get(`/user-app/picklist/details/${this.draftItem.id}`);
        this.draftItem = updatedPicklist.data;

        // Update items
        this.draftItemProductSelection = null;
        this.draftItemProductSelectionNoInventory = false;
        this.draftItemProductBatchSelection = null;
        this.draftItemStorageCellSelection = null;
        this.draftItemQuantity = 0;
      } catch (ex) {
        toasts.error(`Problemă la procesare: ${ex}`);
      } finally {
        this.draftItemBusy = false;
        this.draftItemBusyText = null;
      }
    },

    async submitOptimizeStorageCellSelection() {
      try {
        this.draftItemBusy = true;
        this.draftItemBusyText = 'Se optimizează lista...';

        await httpClient.post(`/user-app/picklist/optimize/${this.draftItem.id}`);

        toasts.success('Lista a fost optimizată');

        const detailedItem = await httpClient.get(`/user-app/picklist/details/${this.draftItem.id}`);
        this.draftItem = detailedItem.data;
      } catch (ex) {
        toasts.error(`Problemă la procesare: ${ex}`);
      } finally {
        this.draftItemBusy = false;
        this.draftItemBusyText = null;
      }
    },

    async submitAssignDraftItem() {
      try {
        this.draftItemBusy = true;
        this.draftItemBusyText = 'Se alocă lista...';

        await httpClient.post(`/user-app/picklist/assign/${this.draftItem.id}?warehouseOperatorId=${this.draftItemWarehouseOperatorSelection.id}`);

        toasts.success('Lista a fost alocată');

        this.$refs.editDraftModal.hide();
        this.draftItem = null;

        await this.loadInitialData();
      } catch (ex) {
        toasts.error(`Problemă la procesare: ${ex}`);
      } finally {
        this.draftItemBusy = false;
        this.draftItemBusyText = null;
      }
    },

    abortEditingDraftLine() {
      if (!this.draftItemEditingLine.isPersisted) {
        //
      }

      this.draftItem.items = this.draftItem.items.filter((item) => item !== this.draftItemEditingLine);

      this.draftItemEditingLine = null;
    },

    async showDetails(id) {
      const detailedItem = await httpClient.get(`/user-app/picklist/details/${id}`);
      this.detailedItem = detailedItem.data;

      this.detailedItem.items = this.detailedItem.items.map((item) => {
        let showDetails = item.currentStatus === 'ProcessedWithException' && !!item.selections && item.selections.length;
        if (!showDetails) {
          showDetails = !!item.selections && item.selections.find((sel) => sel.cellCode !== item.cellCode);
        }
        return Object.assign(item, { _showDetails: showDetails });
      });

      console.log(this.detailedItem);

      this.$refs.itemDetailsModal.show();
    },

    detailItemStatusBadgeVariantMapper(status) {
      if (status === 'ProcessedWithException') {
        return 'warning';
      }
      if (status === 'Processed') {
        return 'success';
      }
      if (status === 'Unprocessed') {
        return 'secondary';
      }

      return 'dark';
    },

    detailItemStatusLabelMapper(status) {
      if (status === 'ProcessedWithException') {
        return 'Proc. cu exc.';
      }
      if (status === 'Processed') {
        return 'Procesat';
      }
      if (status === 'Unprocessed') {
        return 'Neprocesat';
      }

      return status;
    },

    itemStatusBadgeVariantMapper(status) {
      if (status === 'InProgress') {
        return 'primary';
      }
      if (status === 'Pending') {
        return 'secondary';
      }
      if (status === 'Draft') {
        return 'dark';
      }
      if (status === 'Abandoned') {
        return 'light';
      }
      if (status === 'Processed') {
        return 'success';
      }
      if (status === 'ProcessedWithException') {
        return 'warning';
      }

      return 'danger';
    },

    itemStatusLabelMapper(status) {
      if (status === 'InProgress') {
        return 'În lucru';
      }
      if (status === 'Pending') {
        return 'În așteptare';
      }
      if (status === 'Draft') {
        return 'Ciornă';
      }
      if (status === 'Abandoned') {
        return 'Abandonată';
      }
      if (status === 'Processed') {
        return 'Procesată';
      }
      if (status === 'ProcessedWithException') {
        return 'Proc. cu exc.';
      }

      return status;
    },

    filteredCount(status) {
      if (!this.items) {
        return 0;
      }

      return this.items.reduce((acc, item) => (acc + (item.currentStatus === status ? 1 : 0)), 0);
    },

    clipboardSuccessHandler() {
      toasts.success('Textul a fost copiat');
    },

    clipboardErrorHandler() {
      toasts.error('Textul nu a putut fi copiat');
    },
  },
};
</script>
