<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Catalog produse WMS</h2>
      <b-button-toolbar class="pb-3">
        <b-button class="mx-1 p-2" id="addItemButton" @click="beginAddItem" variant="primary" v-b-tooltip.hover>
          <i class="fas fa-plus-square" />
          <span class="d-none d-md-inline ml-1">Produs nou</span>
        </b-button>
      </b-button-toolbar>
    </div>

    <div class="d-flex flex-row">
      <div class="p-1">
        <b-btn @click="refreshList">
          Reîncarcă lista
        </b-btn>
      </div>

      <div class="p-1">
        <b-button :pressed="itemsTableShowId" @click="itemsTableShowId = !itemsTableShowId">
            Afișează ID-uri
        </b-button >
      </div>

      <div class="p-1 flex-fill">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              v-model="itemsFilter"
              type="search"
              placeholder="Filtrează"/>
            <b-input-group-append>
              <b-button :disabled="!itemsFilter" @click="itemsFilter = ''">Resetează</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="itemsCount"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>

      <div class="p-2" v-if="items && items.length">
        {{ items.length }} înregistrări totale
      </div>
    </div>

    <b-table
      show-empty
      small
      striped
      no-footer-sorting
      ref="itemsTable"
      :empty-text="tableBusy ? 'Procesare...' : 'Lista nu are înregistrări'"
      :foot-clone="true"
      primary-key="id"
      :items="items"
      :fields="itemsTableFields"
      :busy.sync="tableBusy"
      :per-page="itemsPerPage"
      :current-page="itemsCurrentPage"
      :filter="itemsFilter"
      :filter-debounce="80"
      @filtered="onItemsTableFiltered"
      class="tweaked-table">
      <template #cell(__actions)="data">
        <b-button
          class="ml-2"
          size="sm"
          @click="showDetails(data.item.id)"
          v-b-tooltip.hover title="Vezi detalii">
          <i class="fas fa-info-circle" />
        </b-button>
        <b-button
          class="ml-2"
          size="sm"
          @click="beginEditItem(data.item)"
          v-b-tooltip.hover title="Editează">
          <i class="fas fa-edit" />
        </b-button>
      </template>

      <template #cell(productCode)="data">
        <span>{{ data.value }}</span>
        <b-button
          class="ml-2"
          variant="outline-secondary"
          size="sm"
          @click="viewBarcode(data.value)">
          <i class="fas fa-barcode" />
        </b-button>
      </template>

      <template #cell(eanCode)="data">
        <span>{{ data.value }}</span>
        <b-button
          class="ml-2"
          variant="outline-secondary"
          size="sm"
          @click="viewBarcode(data.value)">
          <i class="fas fa-barcode" />
        </b-button>
      </template>

      <template #cell(currentStatus)="data">
        <b-badge
          :variant="itemStatusBadgeVariantMapper(data.value)"
          class="badge-lg">
          {{itemStatusLabelMapper(data.value)}}
        </b-badge>
      </template>
    </b-table>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="itemsCount"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>
    </div>

    <b-modal
      ref="viewBarcodeModal"
      id="view-barcode-modal"
      title="Cod bare"
      size="sm"
      ok-only>
      <b-container fluid>
        <img
          :title="viewBarcodeValue"
          :src="textToBase64Barcode(viewBarcodeValue)"
          class="w-100">
      </b-container>
    </b-modal>

    <b-modal
      ref="editItemModal"
      id="edit-item-modal"
      title="Editează produs"
      size="lg"
      @ok="commitEditItem">
      <b-container fluid
        v-if="editItem">
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>ID:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ editItem.id }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod produs:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ editItem.productCode }}</b-col>
        </b-row>
        <div class="row">
          <div class="col">
            <b-form-group label="Cod bare">
              <b-form-input v-model="editItemEanCode" />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Denumire">
              <b-form-input v-model="editItemDescription" />
            </b-form-group>
          </div>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      ref="itemDetailsModal"
      id="item-details-modal"
      title="Detalii"
      size="xl"
      hide-footer
      scrollable>
      <b-container fluid v-if="detailedItem">
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>ID:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.id }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod produs:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.productCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod bare:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.eanCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Denumire:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.description }}</b-col>
        </b-row>
        <!-- <h3>Stoc</h3>
        <b-table
          show-empty
          small
          striped
          no-footer-sorting
          ref="itemsTable"
          empty-text="Lista nu are înregistrări"
          :foot-clone="true"
          primary-key="id"
          :fields="detailTableFields"
          :items="detailedItem.items"
          :busy.sync="detailTableBusy"
          class="tweaked-table">
          <template #cell(pickedProductsCount)="data">
            {{ data.item.selections.reduce((acc, q) => acc + q.pickedProductsCount, 0) }}
          </template>

          <template #foot(requestedProductsCount)>
            {{ detailedItem.items.reduce((acc, q) => acc + q.requestedProductsCount, 0) }}
          </template>
          <template #foot(pickedProductsCount)>
            {{ detailedItem.items.reduce((acc, q) => acc + q.selections.reduce((iacc, s) => iacc + s.pickedProductsCount, 0), 0) }}
          </template>
        </b-table> -->
        <h3>Loturi</h3>
        <b-table
          show-empty
          small
          striped
          no-footer-sorting
          ref="detailProductBatchesTable"
          empty-text="Lista nu are înregistrări"
          :foot-clone="true"
          primary-key="id"
          :fields="detailProductBatchesTableFields"
          :items="detailedItem.productBatches"
          :busy.sync="detailProductBatchesTableBusy"
          class="tweaked-table">
        </b-table>
      </b-container>
    </b-modal>

    <b-modal
      ref="addItemModal"
      id="add-item-modal"
      title="Adaugă produs"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      :ok-disabled="addItemBusy"
      :cancel-disabled="addItemBusy"
      @ok="commitAddItem">
      <b-container fluid>
        <div class="row">
          <div class="col">
            <b-form-group label="Denumire produs">
              <b-form-input v-model="addItemProductDescription" :disabled="addItemBusy"/>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Cod produs">
              <b-form-input v-model="addItemProductCode" :disabled="addItemBusy"/>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Cod bare">
              <b-form-input v-model="addItemEanCode" :disabled="addItemBusy"/>
            </b-form-group>
          </div>
        </div>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import toasts from '@/services/toasts';
import wmsSettings from '@/services/wms';

const { httpClient } = wmsSettings;

const JsBarcode = require('jsbarcode');

const { DateTime } = require('luxon');

export default {
  name: 'Products',
  components: {
  },

  data() {
    return {
      viewBarcodeValue: null,

      addItemBusy: false,
      addItemProductDescription: null,
      addItemProductCode: null,
      addItemEanCode: true,

      editItem: null,
      editItemEanCode: null,
      editItemDescription: null,

      itemsPerPage: 100,
      itemsCurrentPage: 1,
      itemsFilter: '',
      itemsCount: 0,

      items: [],
      itemsTableShowId: false,
      itemsTableFieldsComplete: [
        {
          key: '__actions',
          label: '',
        },
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'productCode',
          label: 'Cod produs',
        },
        {
          key: 'eanCode',
          label: 'Cod bare',
        },
        {
          key: 'description',
          label: 'Denumire',
        },
        {
          key: 'currentStatus',
          label: 'Stare',
        },
      ],
      tableBusy: false,

      detailedItem: null,

      detailTableBusy: false,
      detailTableFields: [
        {
          key: 'lineIndex',
          label: '#',
        },
        {
          key: 'cellCode',
          label: 'Celula',
        },
        {
          key: 'productCode',
          label: 'Cod',
        },
        {
          key: 'eanCode',
          label: 'EAN',
        },
        {
          key: 'description',
          label: 'Descriere',
        },
        {
          key: 'batchCode',
          label: 'Lot',
        },
        {
          key: 'batchExpirationDate',
          label: 'Exp. lot',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
          },
        },
        {
          key: 'requestedProductsCount',
          label: 'Cantitate cerută',
        },
        {
          key: 'pickedProductsCount',
          label: 'Cantitate culeasă',
        },
        {
          key: 'currentStatus',
          label: 'Stare',
        },
      ],

      detailProductBatchesTableBusy: false,
      detailProductBatchesTableFields: [
        {
          key: 'lineIndex',
          label: '#',
        },
        {
          key: 'batchCode',
          label: 'Lot',
        },
        {
          key: 'expiresAt',
          label: 'Exp. lot',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
          },
        },
        {
          key: 'currentStatus',
          label: 'Stare',
        },
      ],
    };
  },

  created() {
    this.loadInitialData();
  },

  computed: {
    itemsTableFields() {
      if (!this.itemsTableShowId) { return this.itemsTableFieldsComplete.filter((x) => x.key !== 'id'); }
      return this.itemsTableFieldsComplete;
    },
  },

  methods: {
    async refreshList() {
      await this.loadInitialData();
      toasts.success('Lista a fost reîncărcată');
    },

    async loadInitialData() {
      this.tableBusy = true;
      try {
        const items = await httpClient.get('/user-app/product/list');
        this.items = items.data || [];
        this.itemsCount = this.items.length;

        this.itemsCurrentPage = 1;
      } finally {
        this.tableBusy = false;
      }
    },

    async onItemsTableFiltered(filteredItems) {
      console.log(filteredItems);
      this.itemsCount = filteredItems.length;
      this.itemsCurrentPage = 1;
    },

    async showDetails(id) {
      const detailedItem = await httpClient.get(`/user-app/product/details/${id}?includeBatchInfo=true`);
      this.detailedItem = detailedItem.data;

      console.log(this.detailedItem);

      this.$refs.itemDetailsModal.show();
    },

    textToBase64Barcode(text, format) {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, text, { format: format || 'CODE128' });
      return canvas.toDataURL('image/png');
    },

    itemStatusBadgeVariantMapper(status) {
      if (status === 'Available') {
        return 'primary';
      }
      if (status === 'Unavailable') {
        return 'light';
      }

      return 'danger';
    },

    itemStatusLabelMapper(status) {
      if (status === 'Available') {
        return 'Activ';
      }
      if (status === 'Unavailable') {
        return 'Inactive';
      }

      return status;
    },

    viewBarcode(value) {
      this.viewBarcodeValue = value;
      this.$refs.viewBarcodeModal.show();
    },

    beginAddItem() {
      this.addItemBusy = false;
      this.addItemProductDescription = null;
      this.addItemProductCode = null;
      this.addItemEanCode = null;

      this.$refs.addItemModal.show();
    },

    async commitAddItem(bvModalEvent) {
      bvModalEvent.preventDefault();

      if (!this.addItemProductDescription
      || !this.addItemProductCode
      || !this.addItemEanCode) {
        toasts.error('Date incomplete!');
        return;
      }

      try {
        const result = await httpClient.post('/user-app/product', {
          categoryId: 1,
          productCode: this.addItemProductCode,
          eanCode: this.addItemEanCode,
          description: this.addItemProductDescription,
        });

        if (!result || !result.data) {
          console.log(result);
          toasts.error('Eroare la comunicare în timpul adăugării!');
        } else {
          toasts.success(`A fost adăugat produsul cu codul ${this.addItemProductCode}`);
        }
      } catch (ex) {
        console.log(ex);
        toasts.error(`Eroare la adăugare: ${ex}`);
      }

      this.$refs.addItemModal.hide();
      await this.loadInitialData();
    },

    beginEditItem(item) {
      this.editItem = item;
      this.editItemEanCode = this.editItem.eanCode;
      this.editItemDescription = this.editItem.description;

      this.$refs.editItemModal.show();
    },

    async commitEditItem(bvModalEvent) {
      bvModalEvent.preventDefault();

      if (!this.editItemEanCode
      || !this.editItemDescription) {
        toasts.error('Date incomplete!');
        return;
      }

      try {
        const result = await httpClient.put(`/user-app/product/${this.editItem.id}`, {
          categoryId: this.editItem.productCategoryId,
          productCode: this.editItem.productCode,
          eanCode: this.editItemEanCode,
          description: this.editItemDescription,
        });

        if (!result || !result.data) {
          console.log(result);
          toasts.error('Eroare la comunicare în timpul editării!');
        } else {
          toasts.success(`A fost modificat produsul cu codul ${this.editItem.productCode}`);
        }
      } catch (ex) {
        console.log(ex);
        toasts.error(`Eroare la editare: ${ex}`);
      }

      this.$refs.editItemModal.hide();
      await this.loadInitialData();
    },
  },
};
</script>
