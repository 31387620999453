<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Liste de recepție</h2>
      <b-button-toolbar class="pb-3">
        <b-button class="mx-1 p-2" id="addItemButton" @click="addItem" variant="primary" v-b-tooltip.hover>
          <i class="fas fa-plus-square" />
          <span class="d-none d-md-inline ml-1">Recepție nouă</span>
        </b-button>
      </b-button-toolbar>
    </div>

    <div class="d-flex flex-row">
      <div class="p-1">
        <b-btn @click="refreshList">
          Reîncarcă lista
        </b-btn>
      </div>

      <div class="p-1">
        <b-button :pressed="itemsTableShowId" @click="itemsTableShowId = !itemsTableShowId">
            Afișează ID-uri
        </b-button >
      </div>

      <div class="p-1 flex-fill">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              v-model="itemsFilter"
              type="search"
              placeholder="Filtrează"/>
            <b-input-group-append>
              <b-button :disabled="!itemsFilter" @click="itemsFilter = ''">Resetează</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>

      <div class="p-2" v-if="items && items.length">
        {{ items.length }} înregistrări totale
      </div>
    </div>

    <b-table
      show-empty
      small
      striped
      no-footer-sorting
      ref="itemsTable"
      empty-text="Lista nu are înregistrări"
      :foot-clone="true"
      primary-key="id"
      hover
      :items="items"
      :fields="itemsTableFields"
      :busy.sync="tableBusy"
      :per-page="itemsPerPage"
      :current-page="itemsCurrentPage"
      :filter="itemsFilter"
      :filter-debounce="80"
      class="tweaked-table">
      <template #cell(currentStatus)="data">
        <b-badge
          :variant="itemStatusBadgeVariantMapper(data.value)"
          class="badge-lg">
          {{itemStatusLabelMapper(data.value)}}
        </b-badge>
      </template>

      <template #cell(__actions)="data">
        <span>{{ data.value }}</span>
        <b-button
          class="ml-2"
          size="sm"
          @click="showDetails(data.item.id)"
          v-b-tooltip.hover title="Vezi detalii">
          <i class="fas fa-info-circle" />
        </b-button>

        <b-button
          v-if="data.item.currentStatus === 'ScanCompleted'"
          class="ml-2"
          size="sm"
          @click="processItem(data.item.id)"
          v-b-tooltip.hover title="Procesează">
          <i class="fas fa-box" />
        </b-button>

        <b-button
          v-if="data.item.currentStatus === 'ScanCompleted'"
          class="ml-2"
          size="sm"
          @click="reopenItem(data.item.id)"
          v-b-tooltip.hover title="Redeschide spre scanare">
          <i class="fas fa-box-open" />
        </b-button>

        <!--
        <b-button
          v-if="data.item.currentStatus === 'Processed'"
          class="ml-2"
          size="sm"
          @click="transferToStorageCell(data.item.id)"
          v-b-tooltip.hover title="Transferă toată lista într-o celulă de depozitare">
          <i class="fas fa-boxes" />
        </b-button>
        -->
      </template>
    </b-table>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>
    </div>

    <b-modal
      ref="transferToStorageCellModal"
      id="transfer-to-storage-cell-modal"
      title="Transferă într-o celulă de depozitare"
      :no-close-on-backdrop="transferToStorageCellBusy"
      :no-close-on-esc="transferToStorageCellBusy"
      :hide-header-close="transferToStorageCellBusy"
      :cancel-disabled="transferToStorageCellBusy"
      :ok-disabled="!transferToStorageCellSelectedOption || transferToStorageCellBusy"
      @ok="commitTransferToStorageCell"
      @shown="transferToStorageCellModalShown"
    >
      <b-container fluid>
        <b-form-group label="Celulă destinație">
          <multiselect
            v-model="transferToStorageCellSelectedOption"
            placeholder="Filtre stare"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            track-by="id"
            label="label"
            :disabled="transferToStorageCellBusy"
            :options="transferToStorageCellOptions"
            :allow-empty="false"
            :multiple="false">
            <template
              slot="option"
              slot-scope="props"
            >
              {{props.option.label}}
            </template><template
              slot="singleLabel"
              slot-scope="props"
            >
            {{props.option.label}}
            </template>
            <template
              slot="tag"
              slot-scope="props">
              {{props.option.label}}
            </template>
            <template
              slot="clear">
            </template>
          </multiselect>
        </b-form-group>
      </b-container>
    </b-modal>

    <b-modal
      ref="addItemModal"
      id="add-item-modal"
      title="Adaugă"
      size="lg"
      @ok="commitAddItem">
      <b-container fluid>
        <div class="row">
          <div class="col">
            <b-form-group label="Operator recepție">
              <b-form-select v-model="addItemWarehouseOperatorId" :options="warehouseOperators"></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Celula recepție">
              <b-form-select v-model="addItemStorageCellId" :options="storageCells"></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Furnizor">
              <b-form-input v-model="addItemSupplierName" />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Document furnizor">
              <b-form-input v-model="addItemSupplierDocument" />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group label="Comentarii">
              <b-form-input v-model="addItemComment" />
            </b-form-group>
          </div>
        </div>
      </b-container>
    </b-modal>

    <b-modal
      ref="itemDetailsModal"
      id="item-details-modal"
      title="Detalii"
      size="xl"
      hide-footer
      scrollable>
      <b-container fluid v-if="detailedItem">
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>ID:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.id }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.listCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Status:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.currentStatus }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Furnizor:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.supplierName }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Document furnizor:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.supplierDocument }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Comentarii:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.comment }}</b-col>
        </b-row>
        <h3>Linii recepție</h3>
        <b-table
          show-empty
          small
          striped
          no-footer-sorting
          ref="itemsTable"
          empty-text="Lista nu are înregistrări"
          :foot-clone="true"
          primary-key="id"
          :items="detailedItem.items"
          :fields="detailTableFields"
          :busy.sync="detailTableBusy"
          class="tweaked-table">
          <template #foot(receivedProductsCount)>
            {{ detailedItem.items.reduce((acc, q) => acc + q.receivedProductsCount, 0) }}
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import toasts from '@/services/toasts';
import wmsSettings from '@/services/wms';

const { warehouseId, httpClient } = wmsSettings;

const { DateTime } = require('luxon');

export default {
  name: 'ReceiveLists',
  components: {
  },

  data() {
    return {
      transferToStorageCellTarget: null,
      transferToStorageCellBusy: false,
      transferToStorageCellMessage: null,
      transferToStorageCellOptions: [],
      transferToStorageCellSelectedOption: null,

      itemsPerPage: 50,
      itemsCurrentPage: 1,
      itemsFilter: '',

      items: [],
      itemsTableShowId: false,
      itemsTableFieldsComplete: [
        {
          key: '__actions',
          label: '',
        },
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Data',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'listCode',
          label: 'Cod listă',
        },
        {
          key: 'warehouseOperatorFullName',
          label: 'Operator',
        },
        {
          key: 'storageCellLabel',
          label: 'Celula',
        },
        {
          key: 'supplierName',
          label: 'Furnizor',
        },
        {
          key: 'supplierDocument',
          label: 'Document furnizor',
        },
        {
          key: 'comment',
          label: 'Comentarii',
        },
        {
          key: 'currentStatus',
          label: 'Stare',
        },
      ],

      detailTableFields: [
        {
          key: 'productCode',
          label: 'Cod produs',
        },
        {
          key: 'eanCode',
          label: 'EAN',
        },
        {
          key: 'description',
          label: 'Descriere',
        },
        {
          key: 'receivedProductsCount',
          label: 'Cantitate',
          thClass: 'col-numeric-value',
          class: 'col-numeric-value text-right',
        },
        {
          key: 'batchCode',
          label: 'Lot',
        },
        {
          key: 'batchExpirationDate',
          label: 'Exp. lot',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
          },
        },
      ],
      detailedItem: null,
      tableBusy: false,
      detailTableBusy: false,

      // Add item stuff
      warehouseOperators: [],
      storageCells: [],

      addItemWarehouseOperatorId: null,
      addItemStorageCellId: null,
      addItemSupplierName: null,
      addItemSupplierDocument: null,
      addItemComment: null,
    };
  },

  created() {
    this.loadInitialData();
  },

  computed: {
    itemsTableFields() {
      if (!this.itemsTableShowId) { return this.itemsTableFieldsComplete.filter((x) => x.key !== 'id'); }
      return this.itemsTableFieldsComplete;
    },
  },

  methods: {

    async refreshList() {
      await this.loadInitialData();
      toasts.success('Lista a fost reîncărcată');
    },

    async loadInitialData() {
      this.tableBusy = true;

      try {
        const items = await httpClient.get(`/user-app/receive-list/list?warehouseId=${warehouseId}`);
        this.items = items.data;
        this.itemsCurrentPage = 1;
      } finally {
        this.tableBusy = false;
      }
    },

    async showDetails(id) {
      const detailedItem = await httpClient.get(`/user-app/receive-list/details/${id}`);
      this.detailedItem = detailedItem.data;

      console.log(this.detailedItem);

      this.$refs.itemDetailsModal.show();
    },

    async processItem(id) {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi procesarea în stoc a recepției?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const result = await httpClient.post(`/user-app/receive-list/finish/${id}`, null);
      // eslint-disable-next-line no-alert
      alert(result);
      await this.loadInitialData();
    },

    async reopenItem(id) {
      if (!(await this.$bvModal.msgBoxConfirm('Confirmi redeschiderea recepției pentru scanare?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      await httpClient.post(`/user-app/receive-list/reopen/${id}`, null);
      toasts.success('Lista de recepție a fost redeschisă pentru scanare');

      await this.loadInitialData();
    },

    async transferToStorageCell(id) {
      this.transferToStorageCellTarget = id;
      this.transferToStorageCellBusy = false;
      this.transferToStorageCellTarget = null;
      this.transferToStorageCellOptions = [];
      this.transferToStorageCellSelectedOption = null;

      this.$refs.transferToStorageCellModal.show();
    },

    async transferToStorageCellModalShown() {
      this.transferToStorageCellBusy = true;
      this.transferToStorageCellTarget = 'Se încarcă celulele de depozitare...';

      try {
        const storageCells = await httpClient.get(`/user-app/storage-cell/list?warehouseId=${warehouseId}&handlesStorage=true`);
        this.transferToStorageCellOptions = storageCells.data;
      } catch (ex) {
        toasts.error(`Eroare la încărcarea listei de celule de depozitare: ${ex}`);
        this.transferToStorageCellModal.hide();
      } finally {
        this.transferToStorageCellBusy = false;
      }
    },

    async addItem() {
      const warehouseOperators = await httpClient.get(`/user-app/warehouse-operator/list?warehouseId=${warehouseId}&warehouseOperatorStatus=Active`);
      this.warehouseOperators = warehouseOperators.data.map((x) => Object.assign(x, { text: x.warehouseOperatorFullName, value: x.id }));

      const storageCells = await httpClient.get(`/user-app/storage-cell/list?warehouseId=${warehouseId}&handlesReception=true`);
      this.storageCells = storageCells.data.map((x) => Object.assign(x, { text: x.label, value: x.id }));

      this.addItemWarehouseOperatorId = null;
      this.addItemStorageCellId = null;
      this.addItemSupplierName = null;
      this.addItemSupplierDocument = null;
      this.addItemComment = null;

      this.$refs.addItemModal.show();
    },

    async commitAddItem(bvModalEvent) {
      bvModalEvent.preventDefault();

      if (!this.addItemWarehouseOperatorId
      || !this.addItemStorageCellId
      || !this.addItemSupplierName
      || !this.addItemSupplierDocument) {
        toasts.error('Date incomplete!');
      }

      try {
        const result = await httpClient.post('/user-app/receive-list', {
          warehouseId,
          warehouseOperatorId: this.addItemWarehouseOperatorId,
          receivingCellId: this.addItemStorageCellId,
          supplierName: this.addItemSupplierName,
          supplierDocument: this.addItemSupplierDocument,
          comment: this.addItemComment,
        });

        if (!result || !result.data || !result.data.id) {
          console.log(result);
          toasts.error('Eroare la comunicare în timpul adăugării!');
        } else {
          toasts.success(`A fost adăugată lista de recepție cu codul ${result.data.listCode}`);
        }
      } catch (ex) {
        console.log(ex);
        toasts.error(`Eroare la adăugare: ${ex}`);
      }

      this.$refs.addItemModal.hide();
      await this.loadInitialData();
    },

    itemStatusBadgeVariantMapper(status) {
      if (status === 'InProgress') {
        return 'primary';
      }
      if (status === 'ScanCompleted') {
        return 'info';
      }
      if (status === 'Abandoned') {
        return 'light';
      }
      if (status === 'Processed') {
        return 'success';
      }

      return 'dark';
    },

    itemStatusLabelMapper(status) {
      if (status === 'InProgress') {
        return 'În lucru';
      }
      if (status === 'ScanCompleted') {
        return 'Scanare fin.';
      }
      if (status === 'Abandoned') {
        return 'Abandonată';
      }
      if (status === 'Processed') {
        return 'Procesată';
      }

      return status;
    },
  },
};
</script>
