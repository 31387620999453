<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Liste de depozitare</h2>
    </div>

    <div class="d-flex flex-row">
      <div class="p-1">
        <b-btn @click="refreshList">
          Reîncarcă lista
        </b-btn>
      </div>

      <div class="p-1">
        <b-button :pressed="itemsTableShowId" @click="itemsTableShowId = !itemsTableShowId">
            Afișează ID-uri
        </b-button >
      </div>

      <div class="p-1 flex-fill">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              v-model="itemsFilter"
              type="search"
              placeholder="Filtrează"/>
            <b-input-group-append>
              <b-button :disabled="!itemsFilter" @click="itemsFilter = ''">Resetează</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>

      <div class="p-2" v-if="items && items.length">
        {{ items.length }} înregistrări totale
      </div>
    </div>

    <b-table
      show-empty
      small
      striped
      no-footer-sorting
      ref="itemsTable"
      empty-text="Lista nu are înregistrări"
      :foot-clone="true"
      primary-key="id"
      hover
      :items="items"
      :fields="itemsTableFields"
      :busy.sync="tableBusy"
      :per-page="itemsPerPage"
      :current-page="itemsCurrentPage"
      :filter="itemsFilter"
      :filter-debounce="80"
      class="tweaked-table">
      <template #cell(currentStatus)="data">
        <b-badge
          :variant="itemStatusBadgeVariantMapper(data.value)"
          class="badge-lg">
          {{itemStatusLabelMapper(data.value)}}
        </b-badge>
      </template>

      <template #cell(__actions)="data">
        <b-button
          class="ml-2"
          size="sm"
          @click="showDetails(data.item.id)"
          v-b-tooltip.hover title="Vezi detalii">
          <i class="fas fa-info-circle" />
        </b-button>
      </template>
    </b-table>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>
    </div>

    <b-modal
      ref="itemDetailsModal"
      id="item-details-modal"
      title="Detalii"
      size="xl"
      hide-footer
      scrollable>
      <b-container fluid v-if="detailedItem">
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>ID:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.id }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.listCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Status:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.currentStatus }}</b-col>
        </b-row>
        <h3>Încărcări</h3>
        <b-table v-if="detailedItem"
          show-empty
          small
          striped
          no-footer-sorting
          ref="itemsTable"
          empty-text="Lista nu are înregistrări"
          :foot-clone="true"
          primary-key="id"
          :items="detailedItem.loadedItems"
          :busy.sync="detailTableBusy"
          class="tweaked-table">
        </b-table>

        <h3>Descărcări</h3>
        <b-table v-if="detailedItem"
          show-empty
          small
          striped
          no-footer-sorting
          ref="itemsTable"
          empty-text="Lista nu are înregistrări"
          :foot-clone="true"
          primary-key="id"
          :items="detailedItem.unloadedItems"
          :busy.sync="detailTableBusy"
          class="tweaked-table">
        </b-table>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import toasts from '@/services/toasts';
import wmsSettings from '@/services/wms';

const { DateTime } = require('luxon');

const { warehouseId, httpClient } = wmsSettings;

export default {
  name: 'StowingLists',
  components: {
  },

  data() {
    return {
      itemsPerPage: 50,
      itemsCurrentPage: 1,
      itemsFilter: '',

      items: [],
      itemsTableShowId: false,
      itemsTableFieldsComplete: [
        {
          key: '__actions',
          label: '',
        },
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Data',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'listCode',
          label: 'Cod listă',
        },
        {
          key: 'warehouseOperatorFullName',
          label: 'Operator',
        },
        {
          key: 'loadedAt',
          label: 'Incărcată',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'completedAt',
          label: 'Descărcată',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'abandonedAt',
          label: 'Abandonată',
          formatter(val) {
            if (!val) {
              return null;
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT);
          },
        },
        {
          key: 'currentStatus',
          label: 'Stare',
        },
      ],

      detailedItem: null,
      tableBusy: false,
      detailTableBusy: false,
    };
  },

  created() {
    this.loadInitialData();
  },

  computed: {
    itemsTableFields() {
      if (!this.itemsTableShowId) { return this.itemsTableFieldsComplete.filter((x) => x.key !== 'id'); }
      return this.itemsTableFieldsComplete;
    },
  },

  methods: {
    async refreshList() {
      await this.loadInitialData();
      toasts.success('Lista a fost reîncărcată');
    },

    async loadInitialData() {
      const items = await httpClient.get(`/user-app/stowing-list/list?warehouseId=${warehouseId}`);
      this.items = items.data;
      this.itemsCurrentPage = 1;
    },

    async showDetails(id) {
      const detailedItem = await httpClient.get(`/user-app/stowing-list/details/${id}`);
      this.detailedItem = detailedItem.data;

      console.log(this.detailedItem);

      this.$refs.itemDetailsModal.show();
    },

    itemStatusBadgeVariantMapper(status) {
      if (status === 'Loading') {
        return 'primary';
      }
      if (status === 'Unloading') {
        return 'secondary';
      }
      if (status === 'Abandoned') {
        return 'light';
      }
      if (status === 'Completed') {
        return 'success';
      }

      return 'dark';
    },

    itemStatusLabelMapper(status) {
      if (status === 'Loading') {
        return 'Încărcare';
      }
      if (status === 'Unloading') {
        return 'Descărcare';
      }
      if (status === 'Abandoned') {
        return 'Abandonată';
      }
      if (status === 'Completed') {
        return 'Completată';
      }

      return status;
    },
  },
};
</script>
