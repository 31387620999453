import httpClient from '@/services/http-client';

function isTokenExpired(token) {
  try {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
  } catch {
    return true;
  }
}

const settings = {
  apiRootUrl: window.location.href.indexOf('://localhost') !== -1
    ? 'http://localhost:5215'
    : 'https://dasco-whm.xconta.cloud',

  loginToken: null,
  warehouseId: '7e4a927a-5135-4ab0-ba1a-572932c9f9c2',
  fallbackStorageCellId: '1C882387-99C7-4717-9570-4DE80C443B2C',
  username: 'xconta',
  password: 'superSecret12!',
};

settings.httpClient = httpClient.create({
  baseURL: settings.apiRootUrl,
});

settings.httpClient.interceptors.request.use(
  async (cfg) => {
    if (!settings.loginToken || isTokenExpired(settings.loginToken)) {
      settings.loginToken = null;
      const loginResult = await httpClient.post(`${settings.apiRootUrl}/user-app/session/login`, { userName: settings.username, password: settings.password });
      settings.loginToken = loginResult.data;
    }

    // eslint-disable-next-line no-param-reassign
    cfg.headers.Authorization = `Bearer ${settings.loginToken}`;
    return cfg;
  },
  (err) => Promise.reject(err),
);

export default settings;
