<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Stoc depozit WMS</h2>
    </div>

    <div class="d-flex flex-row">
      <div class="p-1">
        <b-btn @click="refreshList">
          Reîncarcă lista
        </b-btn>
      </div>

      <div class="p-1">
        <b-button :pressed="itemsTableShowId" @click="itemsTableShowId = !itemsTableShowId">
            Afișează ID-uri
        </b-button >
      </div>

      <div class="p-1 flex-fill">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              v-model="itemsFilter"
              type="search"
              placeholder="Filtrează"/>
            <b-input-group-append>
              <b-button :disabled="!itemsFilter" @click="itemsFilter = ''">Resetează</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <b-button @click="exportWarehouse"><i class="fas fa-file-excel mr-1" />Export Excel</b-button>
        </b-form-group>
      </div>
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>

      <div class="p-2" v-if="items && items.length">
        {{ items.length }} înregistrări totale
      </div>
    </div>

    <b-table
      show-empty
      small
      striped
      no-footer-sorting
      ref="itemsTable"
      :empty-text="tableBusy ? 'Procesare...' : 'Lista nu are înregistrări'"
      :foot-clone="true"
      primary-key="id"
      :items="items"
      :fields="itemsTableFields"
      :busy.sync="tableBusy"
      :per-page="itemsPerPage"
      :current-page="itemsCurrentPage"
      :filter="itemsFilter"
      :filter-debounce="80"
      class="tweaked-table">
      <template #cell(__actions)="data">
        <b-button
          class="ml-2"
          size="sm"
          @click="showDetails(data.item)"
          v-b-tooltip.hover title="Vezi detalii">
          <i class="fas fa-info-circle" />
        </b-button>
      </template>

      <template #cell(productCode)="data">
        <span>{{ data.value }}</span>
        <b-button
          class="ml-2"
          variant="outline-secondary"
          size="sm"
          @click="viewBarcode(data.value)">
          <i class="fas fa-barcode" />
        </b-button>
      </template>

      <template #cell(eanCode)="data">
        <span>{{ data.value }}</span>
        <b-button
          class="ml-2"
          variant="outline-secondary"
          size="sm"
          @click="viewBarcode(data.value)">
          <i class="fas fa-barcode" />
        </b-button>
      </template>

      <template #cell(currentStatus)="data">
        <b-badge
          :variant="itemStatusBadgeVariantMapper(data.value)"
          class="badge-lg">
          {{itemStatusLabelMapper(data.value)}}
        </b-badge>
      </template>
    </b-table>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-pagination
          v-model="itemsCurrentPage"
          :total-rows="items && items.length"
          :per-page="itemsPerPage"
        ></b-pagination>
      </div>
    </div>

    <b-modal
      ref="viewBarcodeModal"
      id="view-barcode-modal"
      title="Cod bare"
      size="sm"
      ok-only>
      <b-container fluid>
        <img
          :title="viewBarcodeValue"
          :src="textToBase64Barcode(viewBarcodeValue)"
          class="w-100">
      </b-container>
    </b-modal>

    <b-modal
      ref="itemDetailsModal"
      id="item-details-modal"
      title="Detalii"
      size="xl"
      hide-footer
      scrollable>
      <b-container fluid v-if="detailedItem">
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>ID:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.id }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod produs:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.productCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Cod bare:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.eanCode }}</b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="5" md="5" class="text-left text-sm-right"><strong>Denumire:</strong></b-col>
          <b-col xs="12" sm="7" md="7" class="text-left">{{ detailedItem.description }}</b-col>
        </b-row>
        <h3>Stoc</h3>
        <b-table
          show-empty
          small
          striped
          no-footer-sorting
          ref="itemsTable"
          empty-text="Lista nu are înregistrări"
          :foot-clone="true"
          primary-key="id"
          :fields="detailTableFields"
          :items="detailedItem.inventoryEntries"
          :busy.sync="detailTableBusy"
          class="tweaked-table">
          <template #cell(lineIndex)="row">
          {{ row.index + 1 }}.
        </template>
          <template #foot(storedQuantity)>
            {{ detailedItem.inventoryEntries.reduce((acc, q) => acc + q.storedQuantity, 0) | displayAsDecimal(3) }}
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import BigNumber from 'bignumber.js';
import toasts from '@/services/toasts';
import wmsSettings from '@/services/wms';

const { warehouseId, httpClient, apiRootUrl } = wmsSettings;

// const XLSX = require('xlsx');
const JsBarcode = require('jsbarcode');

const { DateTime } = require('luxon');

export default {
  name: 'Inventory',
  components: {
  },

  data() {
    return {
      viewBarcodeValue: null,

      editItem: null,
      editItemEanCode: null,
      editItemDescription: null,

      itemsPerPage: 100,
      itemsCurrentPage: 1,
      itemsFilter: '',

      items: [],
      itemsTableShowId: false,
      itemsTableFieldsComplete: [
        {
          key: '__actions',
          label: '',
        },
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'productCode',
          label: 'Cod produs',
        },
        {
          key: 'eanCode',
          label: 'Cod bare',
        },
        {
          key: 'description',
          label: 'Denumire',
        },
        {
          key: 'storedQuantity',
          label: 'Cantitate',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      tableBusy: false,

      detailedItem: null,

      detailTableBusy: false,
      detailTableFields: [
        {
          key: 'lineIndex',
          label: '#',
        },
        {
          key: 'storageCellLabel',
          label: 'Celula',
        },
        {
          key: 'batchCode',
          label: 'Lot',
        },
        {
          key: 'expiresAt',
          label: 'Exp. lot',
          formatter(val) {
            if (!val) {
              return '-';
            }
            return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
          },
        },
        {
          key: 'storedQuantity',
          label: 'Cantitate',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },

  created() {
    this.loadInitialData();
  },

  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
    displayAsPercentage(value, decimals = 0) {
      return `${new BigNumber(value).times(100).decimalPlaces(decimals).toString()} %`;
    },
  },

  computed: {
    itemsTableFields() {
      if (!this.itemsTableShowId) { return this.itemsTableFieldsComplete.filter((x) => x.key !== 'id'); }
      return this.itemsTableFieldsComplete;
    },
  },

  methods: {
    async exportWarehouse() {
      const response = await httpClient({
        url: `${apiRootUrl}/user-app/warehouse/inventory-export/${warehouseId}`,
        method: 'GET',
        responseType: 'blob', // important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'unknown.xlsx';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          [, fileName] = fileNameMatch;
        }
      }
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },

    async refreshList() {
      await this.loadInitialData();
      toasts.success('Lista a fost reîncărcată');
    },

    async loadInitialData() {
      this.tableBusy = true;
      try {
        const items = await httpClient.get(`/user-app/warehouse/inventory/${warehouseId}`);
        this.items = items.data.map((item) => ({
          ...item,
          storedQuantity: item.inventoryEntries.reduce(
            (sum, i) => sum.plus(i.storedQuantity || 0), BigNumber(0),
          ).toNumber(),
        }));

        this.itemsCurrentPage = 1;
      } finally {
        this.tableBusy = false;
      }
    },

    showDetails(item) {
      this.detailedItem = item;

      console.log(this.detailedItem);

      this.$refs.itemDetailsModal.show();
    },

    textToBase64Barcode(text, format) {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, text, { format: format || 'CODE128' });
      return canvas.toDataURL('image/png');
    },

    viewBarcode(value) {
      this.viewBarcodeValue = value;
      this.$refs.viewBarcodeModal.show();
    },
  },
};
</script>
